import DYRequest from "@/services";

export function getSortlist() {
  return DYRequest.post({
    url: "/getsortinfo",
  })
}

export function getFunBySort(sort) {
  return DYRequest.post({
    url: "/getfunbysort",
    data: {
      sort: sort
    }
  })
}

export function getDishList() {
  return DYRequest.post({
    url: "/getDishListBySort"
  })
}
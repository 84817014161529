import styled from "styled-components";

const AppWrapper = styled.div`
  .topheader {
    background-color: #1E1E1E; /* 深色背景 */
    color: #fff; /* 白色文字 */
    padding: 20px 40px; /* 适当的内边距 */
    text-align: center; /* 标题居中 */
    
    h1 {
      font-size: 2.5em; /* 大字号标题 */
      font-weight: 700; /* 字体加粗 */
      margin-bottom: 0.5em; /* 与下方内容的间距 */
      letter-spacing: 1.5px; /* 增加字母间距 */
      font-family: 'Open Sans', sans-serif; /* 专业字体 */
    }
  }

  .topbody {
    width: 1000px;
    margin: 0 auto;
  }

  .footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
  }
`

export default AppWrapper;
import { getFunBySort, getSortlist } from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const fetchHomeData = createAsyncThunk("home/fetchHomeData", (extraInfo, {dispatch, getState}) => {
  getSortlist().then((res) => {
    dispatch(setSortlistAction(res))
  })
})

const fecthFunBySort = createAsyncThunk("home/fetchFunBySort", (sort, {dispatch, getState}) => {
  getFunBySort(sort).then((res) => {
    dispatch(setFunlistAction(res))
  })
})

const homeSlice = createSlice({
  name: "home",
  initialState: {
    sortlistData: [],
    funlistData: [],
  },
  reducers: {
    setSortlistAction(state, {payload}) {
      state.sortlistData = payload.sortlist
    },
    setFunlistAction(state, {payload}) {
      console.log(payload)
      state.funlistData = payload.funlist
    }
  },
});

export default homeSlice.reducer;
export const {setSortlistAction, setFunlistAction} = homeSlice.actions;
export {fetchHomeData, fecthFunBySort}
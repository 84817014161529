import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import "normalize.css";
import "@/assets/css/index.less";
import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <HashRouter>
      <Suspense fallback={<div>Loading...</div>}>
      <App />
      </Suspense>
    </HashRouter>
  </Provider>
);
import { getDishList } from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const fetchSelectDishData = createAsyncThunk("home/fetchSelectDishData", (extraInfo, {dispatch, getState}) => {
  getDishList().then((res) => {
    dispatch(setdishListAction(res.dishlist))
  })
})

const selectDishSlice = createSlice({
  name: "selectDish",
  initialState: {
    dishList: []
  },
  reducers: {
    setdishListAction(state, {payload}) {
      state.dishList = payload
    }
  },
});

export default selectDishSlice.reducer;
export const {setdishListAction} = selectDishSlice.actions;
export {fetchSelectDishData}
import React from "react"
import { Navigate } from "react-router-dom"

const Home = React.lazy(() => import("@/views/home"))
const SelectDish = React.lazy(() => import("@/views/select_dish"))

const route = [
  {
    path: "/",
    element: <Navigate to="/home"/>,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/SelectDish/:funname",
    element: <SelectDish />,
  }
]

export default route
import React, { memo } from 'react'
import AppWrapper from './style'
import { useRoutes } from 'react-router-dom'
import route from './router'

const App = memo(() => {
  return (
    <AppWrapper className='app'>
      <div className="topheader" >
        <h1>欢迎来到螺丝刀工具铺</h1>
      </div>
      <div className='topbody'>
        {useRoutes(route)}
      </div>
      <div className="footer" >
        <h1>友情链接和备案</h1>
      </div>
    </AppWrapper>
  )
})

export default App

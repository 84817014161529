import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./modules/home";
import entireReducer from "./modules/entire";
import selectDishReducer from "./modules/selectDish";

const store = configureStore({
  reducer: {
    home: homeReducer,
    entire: entireReducer,
    selectDish: selectDishReducer,
  },
});

export default store;
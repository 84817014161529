import { BASE_URL, TIMEOUT } from "./config"
import axios from "axios"

class DYRequest {
  constructor(baseURL, timeout) {
    this.instance = axios.create({
      baseURL,
      timeout
    })

    this.instance.interceptors.response.use(res => {
      return res.data
    }, err => {
      console.log(err)
    })
  }

  request(config) {
    return this.instance.request(config)
  }

  get(config) {
    return this.request({...config, method: "get"})
  }
  post(config) {
    return this.request({...config, method: "post"})
  }
}

export default new DYRequest(BASE_URL, TIMEOUT)